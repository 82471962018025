import { DownOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Area } from "@ant-design/plots";
import { DatePicker, Popover, Tooltip } from "antd";
import {
  cloneDeep,
  filter,
  forIn,
  get,
  has,
  isEmpty,
  isUndefined,
  map,
  set,
  sumBy,
} from "lodash";
import moment from "moment";
import React, { useEffect, useState, useMemo } from "react";
import MediaQuery from "react-responsive";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import getProfileDailyProfit from "../../../newApi/robots/getProfileDailyProfit";
import getProfileRobotProfitSummary from "../../../newApi/robots/getProfileRobotProfitSummary";
import getRobots from "../../../newApi/robots/getRobots";
import { formatDecimalNumber } from "../../../utilities/common-function";
import { numberDecimal } from "../../../utilities/startUp";

const roundingDecimal = 2;
const robotArray = [
  {
    _id: "1234a1231",
    title: "robot 1",
  },
  {
    _id: "2222a1231",
    title: "robot 2",
  },
  {
    _id: "3333a1231",
    title: "robot 3",
  },
  {
    _id: "4444a1231",
    title: "robot 4",
  },
  {
    _id: "55555a1231",
    title: "robot 5",
  },
  {
    _id: "6666a1231",
    title: "robot 6",
  },
  {
    _id: "7777a1231",
    title: "robot 7",
  },
];

const robotTypes = ["HF", "HF1", "HF3"];

const dataArray = [
  {
    _id: new Date("03/05/2022"),
    totalProfit: 50,
    totalCommission: 100,
  },
  {
    _id: new Date("04/06/2022"),
    totalProfit: 20,
    totalCommission: 10,
  },
  {
    _id: new Date("05/07/2022"),
    totalProfit: 500,
    totalCommission: 5,
  },
  {
    _id: new Date("12/01/2022"),
    totalProfit: 16,
    totalCommission: 2,
  },
  {
    _id: new Date("03/03/2022"),
    totalProfit: 88,
    totalCommission: 288,
  },
];
const tooltipTextColor = "#00d928";
const ProfitDetails = (props) => {
  const [dataSource, setDataSource] = useState([]);
  const [robotList, setRobotList] = useState([]);
  const [date, setDate] = useState([]);
  const [robot, setRobot] = useState(robotArray);
  const [type, setType] = useState("HF");
  const [dates, setDates] = useState([null, null]);
  const [hackValue, setHackValue] = useState(null);
  const [value, setValue] = useState(null);
  const [filterGroup, setFilterGroup] = useState({});
  const [checkAll, setCheckAll] = useState(false);
  const [userTotalProfit, setUserTotalProfit] = useState(0);
  const [symbolList, setSymbolList] = useState([]);
  const [symbol, setSymbol] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [capitalAmount, setCapitalAmount] = useState(0);
  const [dateRange, setDateRange] = useState([]);
  const [firstPlayDate, setFirstPlayDate] = useState(null);
  const [changeData, setChangeData] = useState(false);
  const { t } = useTranslation();

  // const [profile, setProfile] = useState({});
  const user = get(props, "users.user");
  const profile = get(props, "profile");

  useEffect(() => {
    //getSymbol();
    // getActiveRobot();
    getTotalProfit();
    getData();
    if (!isEmpty(profile)) {
      let capital = get(profile, "wallet.highFrequency.capital");
      let capitalAmountN = 0;
      forIn(capital, (value, key) => {
        capitalAmountN = capitalAmountN + value;
      });
      let limit = get(profile, "wallet.highFrequency.limit");
      let limitAmount = 0;
      forIn(limit, (value, key) => {
        limitAmount = limitAmount + value;
      });

      // console.log("capitalAmount,,", capitalAmountN);
      // console.log("limitAmount,,", limitAmount);

      if (
        isNaN(capitalAmountN) ||
        isUndefined(capitalAmountN) ||
        capitalAmountN === 0
      ) {
        setCapitalAmount(limitAmount);
      } else {
        setCapitalAmount(capitalAmountN);
      }
    }
  }, [props.profile]);

  // useEffect(() => {
  //   getData();
  // }, [totalAmount]);

  useEffect(() => {
    getData();
    getTotalProfit();
  }, [filterGroup, type]);

  // useEffect(() => {
  //   getSymbol();
  // }, [type]);

  useEffect(() => {
    getData();
  }, [symbol]);

  function getData(skip) {
    if (isNaN(parseInt(skip))) {
      skip = 0;
    } else {
      skip = parseInt(skip);
    }
    let robotProfilesData = get(profile, "robotProfiles");
    robotProfilesData = map(robotProfilesData, (item, index) => {
      return get(item, "robotProfileId");
    });
    if (!isEmpty(profile) && !isEmpty(robotProfilesData)) {
      getProfileDailyProfit(
        "all",
        0,
        //getDailyProfitReport('all', 0,
        {
          profileIdIn: robotProfilesData,
          //strategyCode: type,
          type: "day",
          //symbol: symbol,
          ...filterGroup,
        }
      )
        .then((res) => {
          // console.log("profit", res);
          // if (isEmpty(dateRange)) {
          //   setDateRange([get(res, "firstDate"), get(res, "lastDate")]);
          // }
          // if (firstPlayDate === null) {
          //   setFirstPlayDate(get(res, "robotPlayDay") || null);
          // }
          let data = get(res, "data");
          let startAndEndDate = [];
          let temp_dataSource = [];
          let diffDays = 7;

          if (isEmpty(date)) {
            let firstDate = new Date();
            firstDate.setDate(firstDate.getDate() - 6);
            startAndEndDate = [firstDate, new Date()];
          } else {
            startAndEndDate = cloneDeep(date);
            diffDays =
              findDayDifference(startAndEndDate[0], startAndEndDate[1]) + 1;
          }
          let startDate = startAndEndDate[0];
          let endDate = startAndEndDate[1];
          let singleDay = startDate;

          for (let index = 0; index < diffDays; index++) {
            if (index != 0) {
              singleDay = moment(singleDay).add(1, "days");
            }

            let filtered_data = filter(data, (item) => {
              //return get(item,"_id.date") === moment(singleDay).format('YYYY-MM-DD');
              return (
                get(item, "date") === moment(singleDay).format("YYYY-MM-DD")
              );
            });

            let totalProfitDay = 0;
            if (!isEmpty(filtered_data)) {
              filtered_data.forEach((element) => {
                let totalProfit = get(element, "totalProfit");
                totalProfitDay = totalProfitDay + totalProfit;
              });
            }

            let returnPercentage = 0;
            let allCapital = get(profile, "wallet.highFrequency.capital");
            let allLimit = get(profile, "wallet.highFrequency.limit");
            let capitalAmount = 0;

            if (isEmpty(allCapital)) {
              forIn(allLimit, (value, key) => {
                capitalAmount = capitalAmount + value;
              });
            } else {
              forIn(allCapital, (value, key) => {
                capitalAmount = capitalAmount + value;
              });
            }
            // console.log("capital", capitalAmount);

            if (totalProfitDay !== 0) {
              if (capitalAmount === 0) {
                // console.log("here");
                returnPercentage = 0;
              } else {
                totalProfitDay = Math.round(totalProfitDay * 100) / 100;
                returnPercentage = (totalProfitDay / capitalAmount) * 100;
                returnPercentage = Math.round(returnPercentage * 100) / 100;
                //totalProfitDay = formatAmount(totalProfitDay);
              }
            }

            let correctValue = {
              totalProfitDay: totalProfitDay,
              returnPercentage: returnPercentage,
              _id: moment(singleDay).format("L"),
            };

            temp_dataSource.push(correctValue);
          }

          //console.log("temp_dataSource",temp_dataSource);
          setDataSource(temp_dataSource);
          setChangeData(!changeData);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  function getActiveRobot() {
    if (!isEmpty(profile)) {
      getRobots("all", 0, {
        profileId: get(profile, "_id"),
        liquidationNe: 1,
      })
        .then((res) => {
          let data = get(res, "data");
          let temp_amount = sumBy(data, "amount");
          setTotalAmount(temp_amount);
          // console.log("temp amount", temp_amount);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  function findDayDifference(date1, date2) {
    return Math.floor(Math.abs(date2 - date1) / (1000 * 60 * 60 * 24));
  }

  function getTotalProfit() {
    // getProfileRobotProfitReport(1, 0, {
    //   profileId: get(profile, "_id"),
    //   type: "summary",
    //   strategyCode: type,
    // })
    //   .then((res) => {
    //     let profit_total = get(res, "totalProfit");
    //     setUserTotalProfit(profit_total);
    //   })
    //   .catch((err) => {
    //     //console.log(err);
    //   });
    let robotProfilesData = get(profile, "robotProfiles");
    robotProfilesData = map(robotProfilesData, (item, index) => {
      return get(item, "robotProfileId");
    });
    if (!isEmpty(profile) && !isEmpty(robotProfilesData)) {
      getProfileRobotProfitSummary(1, 0, {
        profileIdIn: robotProfilesData,
      })
        .then((res) => {
          // console.log("total", res);
          // console.log("totalProfit", get(res, "totalProfit"));
          setFirstPlayDate(get(res, "startDate"));
          setUserTotalProfit(get(res, "totalProfit"));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  //console.log(dataSource);

  // console.log("capitalAmount", capitalAmount);

  const renderType = [
    {
      text: t("totalAmt", sourceKey.highFrequency),
      value: formatDecimalNumber(capitalAmount, numberDecimal) || 0,
      content: () => {
        return (
          <div
            style={{
              color: tooltipTextColor,
            }}
          >
            {/* 总启动资金 <br />
            Total initiate capital amounts */}
            {t("totalInitiate", sourceKey.highFrequency)}
          </div>
        );
      },
    },
    {
      text: t("totalProfit", sourceKey.highFrequency),
      value: formatDecimalNumber(userTotalProfit || 0, numberDecimal),
      content: () => {
        let currentDay = moment(moment(new Date()).format("YYYY-MM-DD"));
        let playedDay = 0;
        if (firstPlayDate !== null) {
          let momentFormatFirstPlay = moment(
            moment(new Date(firstPlayDate)).format("YYYY-MM-DD")
          );
          playedDay = currentDay.diff(momentFormatFirstPlay, "days");
        }

        return (
          <div
            style={{
              color: tooltipTextColor,
            }}
          >
            {/* 当前总盈利 ({playedDay || 0} 天数) <br />
            Total Profit for your all robots (Started {playedDay || 0} days) */}
            {t("totalProfitForAll", sourceKey.highFrequency)} (
            {t("started", sourceKey.highFrequency)}){playedDay || 0}{" "}
            {t("days", sourceKey.highFrequency)}
          </div>
        );
      },
    },
    {
      text: t("return", sourceKey.highFrequency),
      value: isNaN(
        formatDecimalNumber(
          (userTotalProfit / capitalAmount) * 100 || 0,
          numberDecimal
        )
      )
        ? 0.0
        : `${
            formatDecimalNumber(
              (userTotalProfit / capitalAmount) * 100 || 0,
              numberDecimal
            ) || 0
          }%`,
      content: () => {
        return (
          <div
            style={{
              color: tooltipTextColor,
            }}
          >
            {/* 当前总盈利 / 总启动资金
            <br />
            Total Profit / Total Amount */}
            {t("totalProfit", sourceKey.highFrequency)}/{" "}
            {t("totalAmt", sourceKey.highFrequency)}
          </div>
        );
      },
    },
  ];
  const popOverRobotAndDate = () => {
    let robotArray = cloneDeep(robotList);
    // let dateArray = cloneDeep(date);

    const disabledDate = (current) => {
      // if (!get(dateRange, "[0]") || !get(dateRange, "[1]")) {
      //   return true;
      // } else {
      //   if (get(dates, "[0]") !== null || get(dates, "[1]") !== null) {
      //     let firstDateRange = moment(get(dateRange, "[0]"));
      //     let lastDateRange = moment(get(dateRange, "[1]"));
      //     // if (current > lastDateRange || current < firstDateRange) {
      //     if (
      //       current.isAfter(lastDateRange, "days") ||
      //       current.isBefore(firstDateRange, "days")
      //     ) {
      //       return true;
      //     } else {
      //       const tooLate = dates[0] && current.diff(dates[0], "days") > 6;
      //       const tooEarly = dates[1] && dates[1].diff(current, "days") > 6;
      //       return !!tooEarly || !!tooLate;
      //     }
      //   } else {
      //     current = moment(moment(current).format("YYYY-MM-DD"));
      //     let start = moment(moment(dateRange[0]).format("YYYY-MM-DD"));
      //     let end = moment(moment(dateRange[1]).format("YYYY-MM-DD"));
      //     return current && (current.isBefore(start) || current.isAfter(end));
      //   }
      // }
      //
      // if (!dates) {
      //   return false;
      // }
      // if (dates) {
      //   const tooLate = dates[0] && current.diff(dates[0], "days") > 6;
      //   const tooEarly = dates[1] && dates[1].diff(current, "days") > 6;
      //   return !!tooEarly || !!tooLate;
      // } else {
      //   current = moment(moment(current).format("YYYY-MM-DD"));
      //   let start = moment(moment(dateRange[0]).format("YYYY-MM-DD"));
      //   let end = moment(moment(dateRange[1]).format("YYYY-MM-DD"));
      //   return current && (current.isBefore(start) || current.isAfter(end));
      // }
    };

    const onOpenChange = (open) => {
      if (open) {
        // setHackValue([null, null]);
        setDates([null, null]);
      } else {
        // setHackValue(null);
      }
    };

    const onChangeDate = (e) => {
      // console.log(e);
      let filterValue = cloneDeep(filterGroup);

      if (!isEmpty(e)) {
        let startDate = moment(e).format("YYYY-MM-DD");
        // let endDate = moment(e[1]).format("YYYY-MM-DD");
        let endDate = moment(e, "DD-MM-YYYY").add(6, "d").format("YYYY-MM-DD");

        let startDateTime = moment(startDate + " " + "00:00");
        let endDateTime = moment(endDate + " " + "23:59");

        let transformed_values = [startDateTime, endDateTime];

        set(filterValue, "timestampMin", startDateTime.toDate());
        set(filterValue, "timestampMax", endDateTime.toDate());
        setDate(transformed_values);
      } else {
        setDate([]);
        if (has(filterValue, "timestampMin")) {
          delete filterValue.timestampMin;
        }
        if (has(filterValue, "timestampMax")) {
          delete filterValue.timestampMax;
        }
      }
      setFilterGroup(filterValue);
    };

    const onChangeRobotPopOver = (visible) => {
      if (!visible) {
        setRobotList(robotArray);
      }
    };

    const onChangeDatePopOver = (visible) => {
      if (!visible) {
        // setDate(dateArray);
      }
    };

    return (
      // <Popover
      //   arrowPointAtCenter
      //   content={
      //     <>
      //       {/* <DatePicker.RangePicker
      //         value={date}
      //         disabledDate={disabledDate}
      //         onCalendarChange={(val) => {
      //           console.log("val", val);
      //           if (val === null) {
      //             setDates([null, null]);
      //           } else {
      //             setDates(val);
      //           }
      //         }}
      //         onChange={onChangeDate}
      //         size="small"
      //         onOpenChange={onOpenChange}
      //         style={{ margin: 6 }}
      //       /> */}
      //       <DatePicker
      //         value={date[0]}
      //         // disabledDate={disabledDate}
      //         // onCalendarChange={(val) => {
      //         //   console.log("val", val);
      //         //   if (val === null) {
      //         //     setDates([null, null]);
      //         //   } else {
      //         //     setDates(val);
      //         //   }
      //         // }}
      //         onChange={onChangeDate}
      //         inputReadOnly={true}
      //         size="small"
      //         // onOpenChange={onOpenChange}
      //         style={{ margin: 6 }}
      //       />
      //     </>
      //   }
      //   style={{ padding: 4 }}
      //   onVisibleChange={onChangeDatePopOver}
      //   trigger="click"
      //   placement="bottomLeft"
      // >
      //   <div className="flex justify-center items-center lightgreybox-bg-color mx-4  px-2 rounded-3xl">
      //     <span className="p-2">
      //       {t("chooseDate", sourceKey.highFrequency)} <DownOutlined />
      //     </span>
      //   </div>
      // </Popover>
      <div className="flex justify-center items-center lightgreybox-bg-color mx-4  px-2 rounded-3xl">
        {/* <span className="p-2">
            {t("chooseDate", sourceKey.highFrequency)}
          </span> */}
        <DatePicker
          value={date[0]}
          className="datepicker-no-bordered"
          // disabledDate={disabledDate}
          // onCalendarChange={(val) => {
          //   console.log("val", val);
          //   if (val === null) {
          //     setDates([null, null]);
          //   } else {
          //     setDates(val);
          //   }
          // }}
          onChange={onChangeDate}
          inputReadOnly={true}
          size="small"
          bordered={false}
          // onOpenChange={onOpenChange}
          style={{ margin: 6 }}
        />
      </div>
    );
  };

  const plotBarChart = useMemo(() => {
    let data = [];

    if (!isEmpty(dataSource)) {
      dataSource.forEach((element) => {
        let robotObj = {};
        set(robotObj, "day", moment(get(element, "_id")).format("DD/MM/YYYY"));
        set(robotObj, "Profit", get(element, "returnPercentage"));
        set(robotObj, "amount", get(element, "totalProfitDay"));
        data.push(robotObj);
      });
    }

    const config = {
      data,
      xField: "day",
      yField: "amount",
      padding: [30, 0, 60, 0],
      color: "#c2ce1a",
      maxColumnWidth: 30,
      xAxis: {
        title: {
          text: t("days", sourceKey.highFrequency),
          style: {
            fill: "white",
          },
        },
        label: {
          autoHide: true,
          autoRotate: false,
          autoFit: true,
          style: {
            fill: "white",
          },
        },
      },
      yAxis: false,
      label: {
        position: (data) => {
          const val = parseFloat(data.Profit);
          // if (val <= -1) {
          //   return "middle";
          // } else {
          //   return "top";
          // }
          return "top";
        },
        content: (originData) => {
          const val = parseFloat(originData.Profit);
          if (val !== 0) {
            return val.toFixed(2) + "%";
          }
        },
        style: {
          // fill: "#4ade80",
          fill: "white",
        },
        offsetY: -30,
        offsetX: 10,
      },
      meta: {
        amount: {
          alias: "Profit",
        },
      },
      smooth: true,
      line: {
        color: "white",
      },
      point: {
        size: 5,
        style: {
          fill: "#FFDD02",
        },
      },
    };

    return (
      <div className="theme-bg-color  mt-1 px-2 pb-2 pt-1 mx-3">
        <Area
          className="pt-6 lightgreybox-bg-color rounded-3xl px-2"
          style={{ height: "400px" }}
          {...config}
          autoFit={true}
        />
      </div>
    );
  }, [changeData]);

  return (
    <>
      {/* <Divider>
        <div className="text-base font-semibold text-center">
          策略详情 Strategy Details
        </div>
      </Divider> */}
      <div>
        <div className="grid grid-cols-6 gap-2 mx-5">
          {map(renderType, (item, index) => {
            return (
              <div
                className="col-span-2 relative lightgreybox-bg-color rounded-lg mx-1"
                // style={{ boxShadow: "0px 1px 10px rgba(254, 249, 252, 0.8)" }}
              >
                <div
                  className="flex justify-end my-2 absolute"
                  style={{ right: 3, top: 25 }}
                >
                  <Tooltip
                    className="text-s "
                    trigger="click"
                    overlayStyle={{ whiteSpace: "pre-line" }}
                    title={get(item, "content")}
                    style={{ color: "#00d928" }}
                    placement="bottom"
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                </div>
                <div className="mx-5 my-3 rounded-lg flex justify-center items-center ">
                  {get(item, "text")} <br /> {get(item, "value")}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {plotBarChart}
      {popOverRobotAndDate()}
    </>
  );
};

export default ProfitDetails;
