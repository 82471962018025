import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  FileProtectOutlined,
} from "@ant-design/icons";
import { useLocation } from "@reach/router";
import {
  Button,
  Input,
  message,
  Radio,
  Skeleton,
  Space,
  Spin,
  Table,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { navigate } from "gatsby-link";
import { sumBy } from "lodash";
import {
  cloneDeep,
  filter,
  find,
  forEach,
  get,
  indexOf,
  isEmpty,
  map,
  omit,
  set,
  toLower,
  toNumber,
  uniqBy,
} from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { not_foundIcon } from "../../../images";
import { sourceKey } from "../../../locales/config";
import { useTranslation } from "../../../locales/useTranslation";
import getProfiles from "../../../newApi/profile/getProfiles";
import allRobotActions from "../../../newApi/robots/allRobotActions";
import getHighFrequencyRobots from "../../../newApi/robots/getHighFrequencyRobots";
import getProfileRobotProfitSummary from "../../../newApi/robots/getProfileRobotProfitSummary";
import getRobotProfiles from "../../../newApi/robots/getRobotProfiles";
import getSymbolList from "../../../newApi/robots/getSymbolList";
import { clearModal, triggerModal } from "../../../redux/actions/app-actions";
import {
  updateActiveId,
  updatePreviousPagination,
} from "../../../redux/actions/product-actions";
import {
  loginSuccessful,
  logoutSuccessful,
} from "../../../redux/actions/user-actions";
import { routes } from "../../../route";
import {
  formatAmount,
  formatDecimalNumber,
  getCoinTypeIcon,
  isValidNumber,
} from "../../../utilities/common-function";
import { numberDecimal } from "../../../utilities/startUp";
import ConfirmationModal from "../../general/components/ConfirmationModal";
import Image from "../../general/components/Image";
import Layout from "../../general/components/Layout";
import NewHeader from "../../general/components/NewHeader";
import ProfitDetails from "../../homepage/pages/ProfitDetails";
import SelectLanguageModal from "../../setting/components/SelectLanguageModal";
import { robotUserCanRecoverErrorStatus } from "../config";

const PAGE_SIZE = 50;

const robotTypes = ["HF1", "HF3"];
//let displayRobot = [];
const notFormatCoin = ["BTC", "ETH"];

// markup
const RobotPage = (props) => {
  // const location = useLocation();
  // const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [arrayLoading, setArrayLoading] = useState(true);
  const [overviewData, setOverviewData] = useState([[], [], [], []]);
  const [total, setTotal] = useState([[0], [0], [0], [0]]);
  const [displayRobot, setDisplayRobot] = useState([]);
  const [selectedPlatform, setSelectedPlatform] = useState("binance");
  const [profile, setProfile] = useState({});
  const [allRobotProfiles, setAllRobotProfiles] = useState([]);
  const [profileRobot, setProfileRobot] = useState({});
  const [selectVisible, setSelectVisible] = useState(false);
  const [marketPrices, setMarketPrices] = useState([]);
  const [page, setPage] = useState(1);
  const [userTotalProfit, setUserTotalProfit] = useState(0);
  const [sort, setSort] = useState({});
  const [allActionVisible, setAllActionVisible] = useState(false);
  const [actionType, setActionType] = useState("");
  const [actionUpdate, setActionUpdate] = useState(false);
  const [actionUpdate2, setActionUpdate2] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const { t } = useTranslation();
  let timeoutFunc;

  useEffect(() => {
    getProfileAPI();
    //setArrayLoading(true);
    //getData(1)

    // getData(3)
    //getData(4)
    //setArrayLoading(false);
    if (!isEmpty(get(props, "product.robotListing"))) {
      setPage(get(props, "product.robotListing.previousPage"));
      setSort(get(props, "product.robotListing.previousSorting"));
    }
  }, []);

  useEffect(() => {
    getData(2, (page - 1) * PAGE_SIZE);
  }, [page, sort, actionUpdate2]);

  useEffect(() => {
    /// get tota profit
    getData(2, (page - 1) * PAGE_SIZE);

    let robotProfilesData = get(profile, "robotProfiles");
    robotProfilesData = map(robotProfilesData, (item, index) => {
      return get(item, "robotProfileId");
    });
    if (!isEmpty(profile) && !isEmpty(robotProfilesData)) {
      getProfileRobotProfitSummary(1, 0, {
        profileIdIn: robotProfilesData,
      })
        .then((res) => {
          // console.log("total", res);
          // console.log("totalProfit", get(res, "totalProfit"));
          // setFirstPlayDate(get(res, "startDate"));
          setUserTotalProfit(get(res, "totalProfit"));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [profile, actionUpdate2]);

  useEffect(() => {
    if (get(props, "product.robotListing") && !isEmpty(marketPrices)) {
      window?.scrollTo({
        top: get(props, "product.robotListing.pagePosition"),
        behavior: "smooth",
      });
      props.updatePreviousPagination({});
    }
  }, [marketPrices]);

  useEffect(() => {
    /// get market price
    let dataSource = overviewData[1];

    if (!isEmpty(dataSource)) {
      let symbolList = map(dataSource, (item, index) => {
        return get(item, "symbol");
      });

      symbolList = uniqBy(symbolList);

      let promises = [];
      // console.log("symbol list", symbolList);
      forEach(dataSource, (item, index) => {
        promises.push(
          getSymbolList({
            symbolExact: get(item, "symbol"),
            exchange: get(item, "exchange"),
          })
        );
      });

      Promise.all(promises).then((array) => {
        let newData = map(array, (res, index) => {
          let data = get(res, "data[0]");
          let finalValues = {};
          finalValues = {
            symbol: get(data, "symbol"),
            price: get(data, "price"),
            increase: false,
          };

          if (!isEmpty(marketPrices)) {
            let currentPrice = find(marketPrices, (item2, index) => {
              return get(item2, "symbol") === get(data, "symbol");
            });
            if (get(data, "price") >= get(currentPrice, "price")) {
              set(finalValues, "increase", true);
            }
          }
          return finalValues;
        });
        setMarketPrices(newData);
        // setNewCoinData(newData);
      });
    }
  }, [overviewData]);

  useEffect(() => {
    //// get Robot profiles
    if (!isEmpty(profile)) {
      let robotProfiles = get(profile, "robotProfiles");
      let robotProfileIds = map(robotProfiles, "robotProfileId");
      if (!isEmpty(robotProfiles)) {
        getRobotProfiles({
          _idIn: robotProfileIds,
        })
          .then((res) => {
            let data = get(res, "data");
            setAllRobotProfiles(data);
            let selectedRobot = data[0];
            // setRobotProfile(data[0]);
            setProfileRobot(selectedRobot);
          })
          .catch((err) => {
            console.log(err);
            message.error(err?.message);
          });
      } else {
        setAllRobotProfiles([]);
      }
    }
  }, [profile, actionUpdate]);

  function getData(activeKey, skip) {
    setArrayLoading(true);
    if (!isEmpty(profile) && activeKey === 2) {
      let robotProfiles = get(profile, "robotProfiles");
      if (!isEmpty(robotProfiles)) {
        robotProfiles = map(robotProfiles, (item, index) => {
          return get(item, "robotProfileId");
        });
        // console.log(robotProfiles);
        getHighFrequencyRobots({
          profileIdIn: robotProfiles,
          liquidation: 0,
          limit: PAGE_SIZE,
          skip: skip,
          sort,
        })
          .then((res) => {
            // console.log("robot", res);
            let newOverviewData = cloneDeep(overviewData);
            set(newOverviewData, `[${activeKey - 1}]`, get(res, "data"));
            let newTotal = cloneDeep(total);
            set(newTotal, `[${activeKey - 1}]`, get(res, "total"));
            setTotal(newTotal);
            setOverviewData(newOverviewData);
            let data = get(res, "data");
            if (!isEmpty(data)) {
              let hf1DataSource = [];
              let hf3DataSource = data;
              let robotDisplayTemp = [];
              // if (!isEmpty(hf1DataSource)) {
              //   robotDisplayTemp.push(robotTypes[0]);
              // }
              if (!isEmpty(hf3DataSource)) {
                robotDisplayTemp.push(robotTypes[1]);
              }
              setDisplayRobot(robotDisplayTemp);
              setArrayLoading(false);
            } else {
              setArrayLoading(false);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      setArrayLoading(false);
    }
  }

  function getProfileAPI() {
    if (get(props.user, "user.profile._id")) {
      // console.log("props.user", props.user);

      getProfiles(1, 0, {
        _id: get(props.user, "user.profile._id"),
      })
        .then((res) => {
          // console.log("res", res);
          let data = get(res, "data[0]");
          let robotProfiles = get(data, "robotProfiles");
          if (!isEmpty(robotProfiles)) {
            robotProfiles = map(robotProfiles, (item, index) => {
              return get(item, "robotProfileId");
            });
          }
          // console.log("robotProfiles", robotProfiles);
          setProfile(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  function searchRobot(e, activeKey = "2") {
    let value = e.target.value;
    setSearchValue(e.target.value.trim());
    clearTimeout(timeoutFunc);
    timeoutFunc = setTimeout(() => {
      if (!isEmpty(value) && !isEmpty(profile)) {
        let robotProfiles = get(profile, "robotProfiles");
        if (!isEmpty(robotProfiles)) {
          robotProfiles = map(robotProfiles, (item, index) => {
            return get(item, "robotProfileId");
          });
          // console.log(robotProfiles);
          getHighFrequencyRobots({
            profileIdIn: robotProfiles,
            liquidation: 0,
            limit: "all",
            symbol: value,
            skip: 0,
          })
            .then((res) => {
              // console.log("search", res);
              let newOverviewData = cloneDeep(overviewData);
              set(newOverviewData, `[${activeKey - 1}]`, get(res, "data"));
              let newTotal = cloneDeep(total);
              set(newTotal, `[${activeKey - 1}]`, get(res, "total"));
              setTotal(newTotal);
              setOverviewData(newOverviewData);
              let data = get(res, "data");
              if (!isEmpty(data)) {
                let hf1DataSource = [];
                let hf3DataSource = data;
                let robotDisplayTemp = [];
                // if (!isEmpty(hf1DataSource)) {
                //   robotDisplayTemp.push(robotTypes[0]);
                // }
                if (!isEmpty(hf3DataSource)) {
                  robotDisplayTemp.push(robotTypes[1]);
                }
                setDisplayRobot(robotDisplayTemp);
                // setArrayLoading(false);
              } else {
                // setArrayLoading(false);
              }
            })
            .catch((err) => {
              console.log(err);
              message.error(err?.message);
            });
        }
      } else {
        getData(2);
      }
    }, 1000);
  }

  // const _robotStatusClass = (robot) => {
  //   return indexOf(robotUserCanRecoverErrorStatus, get(robot, "status")) !=
  //     -1 || get(robot, "systemPreStop") === 1
  //     ? "rounded-md bg-red-500 text-white"
  //     : get(robot, "isPreStop") !== 0
  //     ? "rounded-md bg-main-color-gradient"
  //     : "";
  // };

  const _robotStatusClass = (robot) => {
    let color = "";
    let exchange = get(robot, "exchange");
    let robotProfileData = filter(allRobotProfiles, (item, index) => {
      return get(item, "exchange") === exchange;
    });
    robotProfileData = robotProfileData[0];
    if (robot?.customLiquidation === 1) {
      color = "rounded-md  bg-green-500 text-white";
    } else if (
      indexOf(robotUserCanRecoverErrorStatus, get(robot, "status")) !== -1 ||
      get(robot, "systemPreStop") === 1
    ) {
      color = "rounded-md bg-red-500 text-white";
    } else {
      if (
        get(robot, "isPreStop") !== 0 ||
        get(robotProfileData, "settings.highFrequency.preStop") === 1 ||
        get(robotProfileData, "settings.highFrequency.preStop") === 2
      ) {
        color = "rounded-md bg-main-color-gradient";
      }
    }

    return color;
  };

  const _renderTable = (key, robotType, dataSource) => {
    let columns = [];

    //Do the dataSource checking, remove dummy dataSource below.
    dataSource = [];
    let dataTotal = 0;

    switch (key) {
      case 2:
        columns = [
          {
            title: (
              <React.Fragment>
                <div className="text-sm text-center">
                  {/* 币种 <br /> */}
                  {t("coinType", sourceKey.highFrequency)}
                </div>
              </React.Fragment>
            ),
            dataIndex: "symbol",
            key: "symbol",
            width: 180,
            fixed: "left",
            render: (value, record) => {
              let convertedSymbol = get(record, "symbol")?.replace("USDT", "");
              let symbol = get(record, "symbol");
              let marketPricesData = find(marketPrices, (item, index) => {
                return get(item, "symbol") === symbol;
              });
              return {
                props: {},
                children: (
                  <div
                    className={`flex justify-start px-0.5  ${_robotStatusClass(
                      record
                    )}`}
                  >
                    <div className="flex grid grid-cols-12">
                      <div
                        className="flex justify-center items-center col-span-3"
                        style={{ width: "25px" }}
                      >
                        <Image
                          src={getCoinTypeIcon(toLower(convertedSymbol))}
                          style={{ width: 25, height: 25 }}
                          className=" mt-0.5 mr-1 pl-0.5"
                        />
                      </div>

                      <div
                        className={`flex text-xs text-center py-1 cursor-pointer col-span-5`}
                        style={{ width: "160px" }}
                      >
                        <div className="">
                          <div className="flex justify-start">
                            {`${convertedSymbol} ${
                              get(record, "remark") ? `(${record.remark})` : ""
                            }`}
                          </div>
                          <div
                            className="flex justify-start"
                            style={{ fontSize: "0.5rem" }}
                          >
                            <span>
                              ID:{" "}
                              {get(record, "_id")?.substring(
                                get(record, "_id").length - 4
                              )}{" "}
                              {get(record, "enableLinkRobot") === 1
                                ? `/ ${get(record, "linkedRobotId")?.substring(
                                    get(record, "linkedRobotId").length - 4
                                  )}`
                                : ""}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="pl-1 py-1">
                        <div
                          className={`flex justify-start ${
                            get(marketPricesData, "price") >=
                            get(record, "minPrice")
                              ? record?.customLiquidation === 1
                                ? ""
                                : "text-green-500"
                              : "text-red-500"
                          }`}
                          style={{ fontSize: "11px" }}
                        >
                          {indexOf(notFormatCoin, convertedSymbol) !== -1
                            ? formatDecimalNumber(
                                get(marketPricesData, "price"),
                                0
                              )
                            : formatDecimalNumber(
                                get(marketPricesData, "price"),
                                4
                              )}
                          {/* {get(marketPricesData, "price")} */}
                        </div>
                        <div
                          className="flex justify-start"
                          // style={{ fontSize: "0.55rem" }}
                          style={{ fontSize: "11px" }}
                        >
                          {indexOf(notFormatCoin, convertedSymbol) !== -1
                            ? formatDecimalNumber(get(record, "minPrice"), 0)
                            : formatDecimalNumber(get(record, "minPrice"), 4)}
                          {/* {formatDecimalNumber(get(record, "minPrice"), 4)} */}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
              };
            },
          },
          {
            title: (
              <React.Fragment>
                <div
                  className="text-sm  text-center cursor-pointer"
                  onClick={() => {
                    if (get(sort, "profitEarned") == 1) {
                      setSort({
                        profitEarned: -1,
                      });
                    } else if (get(sort, "profitEarned") == -1) {
                      setSort(omit(sort, "profitEarned"));
                    } else {
                      setSort({
                        profitEarned: 1,
                      });
                    }
                  }}
                >
                  {t("pnl", sourceKey.highFrequency)}
                  {get(sort, "profitEarned") == 1 && (
                    <ArrowUpOutlined
                      style={{ color: "green" }}
                      className="ml-2"
                    />
                  )}
                  {get(sort, "profitEarned") == -1 && (
                    <ArrowDownOutlined
                      style={{ color: "red" }}
                      className="ml-2"
                    />
                  )}
                </div>
              </React.Fragment>
            ),
            dataIndex: "profitEarned",
            width: 80,
            key: "profitEarned",
            render: (value, record) => {
              return (
                <div
                  className={`text-sm text-center ${
                    isValidNumber(parseFloat(value))
                      ? parseFloat(value) > 0
                        ? "text-green-500"
                        : parseFloat(value) < 0
                        ? "text-red-500"
                        : ""
                      : ""
                  }`}
                >
                  {formatDecimalNumber(parseFloat(value || 0), numberDecimal)}
                </div>
              );
            },
          },
          {
            title: (
              <React.Fragment>
                <div
                  className="text-sm  text-center cursor-pointer"
                  onClick={() => {
                    if (get(sort, "balanceOrder") === 1) {
                      setSort({
                        balanceOrder: -1,
                      });
                    } else if (get(sort, "balanceOrder") === -1) {
                      setSort(omit(sort, "balanceOrder"));
                    } else {
                      setSort({
                        balanceOrder: 1,
                      });
                    }
                  }}
                >
                  {t("balanceOrder", sourceKey.highFrequency)}
                  {get(sort, "balanceOrder") === 1 && (
                    <ArrowUpOutlined
                      style={{ color: "green" }}
                      className="ml-2"
                    />
                  )}
                  {get(sort, "balanceOrder") === -1 && (
                    <ArrowDownOutlined
                      style={{ color: "red" }}
                      className="ml-2"
                    />
                  )}
                </div>
              </React.Fragment>
              // <React.Fragment>
              //   <div className="text-sm  text-center">
              //     {/* 剩余仓数 <br /> */}
              //     {t("balanceOrder", sourceKey.highFrequency)}
              //   </div>
              // </React.Fragment>
            ),
            width: 75,
            dataIndex: "balanceOrder",
            key: "balanceOrder",
            render: (value, record) => {
              // value = find(currentOrders, ['symbol', get(record, 'symbol')]);
              let symbol = get(record, "symbol");
              let marketPricesData = find(marketPrices, (item, index) => {
                return get(item, "symbol") === symbol;
              });
              let minPrice = get(record, "minPrice");
              let maxPrice = get(record, "maxPrice") || 100000000;
              let error = false;
              if (
                get(marketPricesData, "price") < minPrice ||
                get(record, "balanceOrder") === 60 
              ) {
                error = true;
              }
              if (
                get(marketPricesData, "price") >= maxPrice &&
                get(record, "balanceOrder") === 0 
              ) {
                error = true;
              }

              return (
                <div
                  className={`text-sm text-center ${
                    error ? "text-red-500" : ""
                  }`}
                >
                  {get(record, "balanceOrder") || 0}
                </div>
              );
            },
          },
          {
            title: (
              <React.Fragment>
                <div
                  className="text-sm  text-center cursor-pointer"
                  onClick={() => {
                    if (get(sort, "totalCover") === 1) {
                      setSort({
                        totalCover: -1,
                      });
                    } else if (get(sort, "totalCover") === -1) {
                      setSort(omit(sort, "totalCover"));
                    } else {
                      setSort({
                        totalCover: 1,
                      });
                    }
                  }}
                >
                  {t("cover", sourceKey.highFrequency)}
                  {get(sort, "totalCover") === 1 && (
                    <ArrowUpOutlined
                      style={{ color: "green" }}
                      className="ml-2"
                    />
                  )}
                  {get(sort, "totalCover") === -1 && (
                    <ArrowDownOutlined
                      style={{ color: "red" }}
                      className="ml-2"
                    />
                  )}
                </div>
              </React.Fragment>
            ),
            dataIndex: "activeStocks",
            width: 75,
            key: "activeStocks",
            render: (value, record) => {
              return (
                <div className="text-sm text-center">
                  {get(record, "totalCover") || 0}
                </div>
              );
            },
          },
          {
            title: (
              <React.Fragment>
                <div
                  className="text-sm  text-center cursor-pointer"
                  onClick={() => {
                    if (get(sort, "amount") === 1) {
                      setSort({
                        amount: -1,
                      });
                    } else if (get(sort, "amount") === -1) {
                      setSort(omit(sort, "amount"));
                    } else {
                      setSort({
                        amount: 1,
                      });
                    }
                  }}
                >
                  {t("amount", sourceKey.highFrequency)}
                  {get(sort, "amount") === 1 && (
                    <ArrowUpOutlined
                      style={{ color: "green" }}
                      className="ml-2"
                    />
                  )}
                  {get(sort, "amount") === -1 && (
                    <ArrowDownOutlined
                      style={{ color: "red" }}
                      className="ml-2"
                    />
                  )}
                </div>
              </React.Fragment>
            ),
            width: 85,
            dataIndex: "amount",
            key: "amount",
            render: (value, record) => {
              return (
                <div className="text-sm text-center">
                  {/* {robotType} / {get(record, "amount")} */}
                  {get(record, "amount")}
                </div>
              );
            },
          },
          {
            title: (
              <React.Fragment>
                <div
                  className=" cursor-pointer text-sm  text-center"
                  onClick={() => {
                    if (get(sort, "exchange") === 1) {
                      setSort({
                        exchange: -1,
                      });
                    } else if (get(sort, "exchange") === -1) {
                      setSort(omit(sort, "exchange"));
                    } else {
                      setSort({
                        exchange: 1,
                      });
                    }
                  }}
                >
                  {t("platform", sourceKey.highFrequency)}
                  {get(sort, "exchange") === 1 && (
                    <ArrowUpOutlined
                      style={{ color: "green" }}
                      className="ml-2"
                    />
                  )}
                  {get(sort, "exchange") === -1 && (
                    <ArrowDownOutlined
                      style={{ color: "red" }}
                      className="ml-2"
                    />
                  )}
                </div>
              </React.Fragment>
            ),
            dataIndex: "exchange",
            key: "exchange",
            width: 100,
            render: (value, record) => {
              return (
                <div className="text-sm text-center">
                  {/* {robotType} / {get(record, "amount")} */}
                  {t(get(record, "exchange"), sourceKey.highFrequency)}
                </div>
              );
            },
          },
        ];

        switch (robotType) {
          // case robotTypes[0]:
          //   dataSource = overviewData[1].filter((record) => {
          //     return !get(record, "minPrice");
          //   });
          //   break;
          case robotTypes[1]:
            dataSource = overviewData[1].filter((record) => {
              return get(record, "minPrice") || !get(record, "minPrice");
            });
            break;

          default:
            break;
        }
        dataTotal = dataSource.size;

        break;
      default:
        break;
    }

    return (
      <>
        {
          // arrayLoading ? (
          //   <Skeleton active rows={5}></Skeleton>
          // ) :
          isEmpty(dataSource) ? (
            <div className="mt-7">
              <img
                src={not_foundIcon}
                className=" object-contain h-full w-full"
                style={{ height: "10vh" }}
              />

              <br></br>
              <div className="flex justify-center items-center">
                <div
                  className="flex justify-center items-center font-black text-l text-center mb-2 text-gray-400"
                  style={{ width: 310 }}
                >
                  没有记录
                  <br />
                  No Data Found
                </div>
              </div>
            </div>
          ) : (
            <Table
              className="rounded-2xl  mx-4 mt-1 no-row-hover"
              scroll={{ x: 500 }}
              size={"small"}
              columns={columns}
              pagination={{
                simple: true,
                total: total[1],
                current: page,
                onChange: (page_selected) => {
                  // console.log(page_selected);
                  setPage(page_selected);
                },
                pageSize: PAGE_SIZE,
                showSizeChanger: false,
              }}
              dataSource={dataSource}
              // rowClassName={(record) => {
              //   return key == 2 && get(find(robotStatus, ['robotId', get(record, 'robotId')]), 'isPreStop') ? 'bg-main-color-gradient' : ''
              // }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    switch (key) {
                      case 1:
                        props.updateActiveId(get(record, "_id"));
                        navigate(routes.futureDetails.to());
                        break;
                      case 2:
                        props.updateActiveId(get(record, "_id"));
                        const position = window.pageYOffset;
                        props.updatePreviousPagination({
                          page,
                          position,
                          sort,
                        });
                        navigate(routes.highFrequencyDetails.to());
                        break;
                      case 3:
                        props.updateActiveId(get(record, "_id"));
                        navigate(routes.safetyPinDetails.to());
                        break;
                      case 4:
                        props.updateActiveId(get(record, "_id"));
                        navigate(routes.chaseDetails.to());
                        break;

                      default:
                        break;
                    }
                  }, // click row
                  onDoubleClick: (event) => {}, // double click row
                  onContextMenu: (event) => {}, // right button click row
                  onMouseEnter: (event) => {}, // mouse enter row
                  onMouseLeave: (event) => {}, // mouse leave row
                };
              }}
            ></Table>
          )
        }
      </>
    );
  };

  function _renderTableSummary(robotType, dataSource) {
    dataSource = [];
    let dataTotal = 0;

    switch (robotType) {
      // case robotTypes[0]:
      //   dataSource = overviewData[1].filter((record) => {
      //     return !get(record, "minPrice");
      //   });
      //   break;
      case robotTypes[1]:
        dataSource = overviewData[1].filter((record) => {
          return get(record, "minPrice") || !get(record, "minPrice");
        });
        break;

      default:
        break;
    }

    dataTotal = dataSource.size;

    let numRobots = total[1];
    let cover = sumBy(allRobotProfiles, "totalCover") || 0;
    let balance = sumBy(allRobotProfiles, "balanceOrder") || 0;
    let stockAmount = sumBy(allRobotProfiles, "totalRobotAmount") || 0;
    let pnl = 0;

    // dataSource.map((record) => {
    //   cover += toNumber(record.totalCover);
    //   balance += toNumber(record.balanceOrder);
    //   pnl += toNumber(record.profitEarned || 0);
    //   stockAmount += toNumber(record.amount || 0);
    // });

    let totalLimit = 0;
    let totalUsed = 0;

    let selectedRobotProfiles = filter(allRobotProfiles, (item, index) => {
      return get(item, "exchange") === selectedPlatform;
    });
    selectedRobotProfiles = selectedRobotProfiles[0];

    return (
      <div className="px-2 mx-3 mt-2">
        <div className="bg-main-color-gradient rounded-3xl px-4 pb-2 pt-2">
          <div className="mx-4 mb-2">
            {/* <span className=" mt-2 font-semibold">
              
            </span> */}
            <div className="mt-4">
              <div className="flex justify-start">
                <span className={`inline-block w-1/3 `}>
                  {" "}
                  {t("walletLimit", sourceKey.highFrequency)}
                </span>
                <span className="inline-block mx-2">:</span>
                <span className="inline-block w-1/2 ">
                  {isEmpty(selectedRobotProfiles)
                    ? 0
                    : formatDecimalNumber(
                        get(
                          selectedRobotProfiles,
                          "robotFundLimit.highFrequency.used"
                        ),
                        // 10000000,
                        numberDecimal
                      )}{" "}
                  /{" "}
                  {isEmpty(selectedRobotProfiles)
                    ? 0
                    : formatDecimalNumber(
                        get(
                          selectedRobotProfiles,
                          "robotFundLimit.highFrequency.limit"
                        ),
                        // 10000000,
                        0
                      )}
                </span>
              </div>
              <div className="flex justify-start">
                <span className={`inline-block w-1/3 `}>
                  {" "}
                  {t("totalPosition", sourceKey.highFrequency)}
                </span>
                <span className="inline-block mx-2">:</span>
                <span className="inline-block w-1/2 ">
                  {formatDecimalNumber(stockAmount, 0)}
                </span>
              </div>
            </div>
          </div>

          <div className="border border-b-gray-600"></div>

          <div className="grid grid-cols-4 gap-1 mt-4 ml-6 font-semibold">
            {/* <div className="col-span-4">{robotType}</div> */}
            <div className="col-span-2 flex justify-start">
              <span className={`inline-block w-1/2 `}>
                {" "}
                {t("robot", sourceKey.highFrequency)}
              </span>
              <span className="inline-block mx-2">:</span>
              <span className="inline-block w-1/2 ">
                {arrayLoading ? (
                  <Skeleton.Button active size="small"></Skeleton.Button>
                ) : isEmpty(dataSource) ? (
                  0
                ) : (
                  numRobots
                )}
              </span>
            </div>
            <div className="col-span-2 flex justify-start">
              <span className={`inline-block w-1/2 `}>
                {" "}
                {t("cover", sourceKey.highFrequency)}
              </span>
              <span className="inline-block mx-2">:</span>
              <span className="inline-block w-1/2 ">
                {arrayLoading ? (
                  <Skeleton.Button active size="small"></Skeleton.Button>
                ) : isEmpty(dataSource) ? (
                  0
                ) : (
                  cover
                )}
              </span>
            </div>
            <div className="col-span-2 flex justify-start">
              <span className={`inline-block w-1/2 `}>
                {" "}
                {t("balanceOrder", sourceKey.highFrequency)}
              </span>
              <span className="inline-block mx-2">:</span>
              <span className="inline-block w-1/2 ">
                {arrayLoading ? (
                  <Skeleton.Button active size="small"></Skeleton.Button>
                ) : isEmpty(dataSource) ? (
                  0
                ) : (
                  balance
                )}
              </span>
            </div>

            <div className="col-span-2 flex justify-start">
              <span className={`inline-block w-1/2 `}>
                {" "}
                {t("pnl", sourceKey.highFrequency)}
              </span>
              <span className="inline-block mx-2">:</span>
              <span className="inline-block w-1/2 ">
                {arrayLoading ? (
                  <Skeleton.Button active size="small"></Skeleton.Button>
                ) : isEmpty(dataSource) ? (
                  0
                ) : (
                  formatDecimalNumber(userTotalProfit, numberDecimal) || 0
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const _renderActionsConfirm = () => {
    function allActions() {
      console.log(actionType);
      setLoading(true);
      let robotProfileId = map(allRobotProfiles, "_id");
      allRobotActions({
        robotProfileId: robotProfileId,
        actionType,
      })
        .then((res) => {
          // console.log(res);

          message.success(t("success"));
          setAllActionVisible(false);
          setActionType("");
          if (actionType === "restart") {
            setActionUpdate2(!actionUpdate2);
          } else {
            setActionUpdate(!actionUpdate);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }

    function situationWords(profile) {
      if (isEmpty(profile)) {
        return "-";
      }
      let prestop = get(profile, "settings.highFrequency.preStop");

      switch (prestop) {
        case 0:
          return t("null", sourceKey.highFrequency);
        case 1:
          return t("tempSell", sourceKey.highFrequency);
        case 2:
          return t("stopBuy", sourceKey.highFrequency);
        default:
          return "-";
      }
    }

    return (
      <ConfirmationModal
        visible={allActionVisible}
        onClose={() => {
          setAllActionVisible(false);
        }}
        // onOk={() => {}}
        confirmButton={(handleOk) => {}}
      >
        <div className="mt-2 mb-3">
          <React.Fragment>
            {t("chooseActions", sourceKey.highFrequency)}
            <div className="my-3">
              {t("currentSituation", sourceKey.highFrequency)} : <br />
              <span className="font-bold text-green-400">
                {situationWords(profileRobot)}
              </span>
            </div>
            <div className=" pl-5 mt-2">
              <Radio.Group
                name="radiogroup"
                value={actionType}
                onChange={(e) => {
                  setActionType(e.target.value);
                }}
              >
                <Space direction="vertical">
                  <div className="mb-2">
                    {get(profileRobot, "settings.highFrequency.preStop") !==
                      0 &&
                      get(profileRobot, "settings.highFrequency.preStop") && (
                        <Radio value="cancelPreStop" style={{ color: "white" }}>
                          {t("cancelPrestop", sourceKey.highFrequency)}
                        </Radio>
                      )}
                  </div>
                  <div className="mb-2">
                    <Radio value="tempSell" style={{ color: "white" }}>
                      {t("tempSell", sourceKey.highFrequency)}
                    </Radio>
                  </div>
                  <div className="mb-2">
                    <Radio value="stopBuy" style={{ color: "white" }}>
                      {t("stopBuy", sourceKey.highFrequency)}
                    </Radio>
                  </div>
                  <div className="mb-2">
                    <Radio value="restart" style={{ color: "white" }}>
                      {t("restart", sourceKey.highFrequency)}
                    </Radio>
                  </div>
                </Space>
              </Radio.Group>
            </div>
          </React.Fragment>
        </div>

        <div className="flex justify-around ">
          <span
            className="rounded-md main-button-color px-3 py-1.5 cursor-pointer"
            onClick={() => {
              allActions();
            }}
          >
            {/* 确认 Confirm */}
            {t("confirm")}
          </span>

          <span
            className=" px-3 py-1.5 second-button-color border rounded-md cursor-pointer"
            onClick={() => {
              setAllActionVisible(false);
            }}
          >
            {t("cancel")}
          </span>
        </div>
      </ConfirmationModal>
    );
  };

  return (
    <React.Fragment>
      <Layout>
        <Spin spinning={loading}>
          {/* <NavHeader
            showBack={true}
            suffix={
              <React.Fragment>
                <div className="justify-end flex items-center">
                  {t("performance", sourceKey.highFrequency)}
                </div>
              </React.Fragment>
            }
          > */}
          <NewHeader>
            <div className="">
              <div className="">
                <ProfitDetails
                  users={get(props, "user.user")}
                  profile={profile}
                />
              </div>

              <div className="flex justify-between px-5 mt-3">
                <div className="font-bold">
                  {/* 持仓列表  */}
                  {t("openPositionList", sourceKey.highFrequency)}
                </div>
                <div>
                  <FileProtectOutlined
                    className="black  mx-2"
                    style={{ fontSize: 23 }}
                    onClick={() => navigate(routes.liquidizedRobotList.to())}
                  />
                </div>
              </div>

              {!isEmpty(displayRobot) ? (
                <>
                  {displayRobot.map((robotType) => {
                    return (
                      <>
                        {_renderTableSummary(robotType)}
                        <div className="mt-1 flex justify-between px-5 py-1">
                          <div
                            className="  text-white"
                            style={{ width: "100%" }}
                          >
                            <Input
                              onChange={searchRobot}
                              placeholder={t("search", sourceKey.pairing)}
                              style={{
                                width: "80%",
                                borderRadius: "12px",
                                boxShadow:
                                  "0px 0px 12px rgba(111, 111, 111, 0.1)",
                              }}
                              allowClear={true}
                              value={searchValue}

                            />
                          </div>
                          <div
                            className="text-white"
                            // style={{ width: "100%" }}
                          >
                            <Button
                              onClick={() => {
                                setAllActionVisible(true);
                              }}
                            >
                              {t("allRobotActions", sourceKey.highFrequency)}
                            </Button>
                          </div>
                        </div>
                        {_renderTable(2, robotType)}
                      </>
                    );
                  })}
                </>
              ) : arrayLoading === true ? (
                <>
                  <Skeleton active rows={5}></Skeleton>
                  <Skeleton active rows={5}></Skeleton>
                  <Skeleton active rows={5}></Skeleton>
                </>
              ) : (
                <div className="items-center justify-center mt-10 pb-32">
                  <img
                    src={not_foundIcon}
                    className=" object-contain h-full w-full"
                    style={{ height: "15vh" }}
                  />

                  <br></br>
                  <div className="flex justify-center items-center">
                    <div
                      className="flex justify-center items-center font-black text-base text-center mb-2 text-gray-400"
                      style={{ width: 310 }}
                    >
                      {/* 您暂时还没创建任何机器人。 */}
                      {/* <br /> */}
                      {t("noRobot", sourceKey.highFrequency)}
                    </div>
                  </div>

                  <div className="flex justify-center items-center">
                    <Button
                      block
                      className="main-button-color text-xl my-3 "
                      style={{ width: 250, borderRadius: 25, height: 65 }}
                      onClick={() => navigate(routes.highFrequency.to())}
                      size="large"
                    >
                      {/* 创建机器人 <br /> */}
                      {/* Create Robot */}
                      {t("createRobot", sourceKey.highFrequency)}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </NewHeader>
          {_renderActionsConfirm()}

          {/* </NavHeader> */}
        </Spin>
        <SelectLanguageModal
          visible={selectVisible}
          onClose={() => {
            setSelectVisible(false);
          }}
        />
      </Layout>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  product: state.product,
});

const mapDispatchToProps = {
  loginSuccessful,
  updateActiveId,
  logoutSuccessful,
  clearModal,
  triggerModal,
  updatePreviousPagination,
};
export default connect(mapStateToProps, mapDispatchToProps)(RobotPage);
